import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is the complete list of my published apps:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "geosense"
        }}>{`Geosense`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "gdrive"
        }}>{`GDrive`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "fon-auto-login"
        }}>{`Fon Auto-Login`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "the-bro-code"
        }}>{`The Bro Code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "quadratic-solver"
        }}>{`Quadratic Solver`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "bristol-pound"
        }}>{`Bristol Pound`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "o-meu-salario"
        }}>{`O meu Salário`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "totojogos"
        }}>{`Totojogos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "ctt-objectos"
        }}>{`CTT Objectos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "the-other-side"
        }}>{`The Other Side`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      